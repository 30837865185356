var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inventoryDetails page-info-content"},[_c('DetailsPage',{attrs:{"detail-obj":_vm.inventoryDetailObj,"details-item-arr":_vm.detailsItemArr}}),_c('Table',{attrs:{"item-data":_vm.itemDataDetails,"list-data":_vm.itemDataList,"loading":_vm.loading},scopedSlots:_vm._u([{key:"invWeight",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.invWeight)+_vm._s(row.unit))])]}},{key:"avlbWeight",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.avlbWeight)+_vm._s(row.unit))])]}},{key:"frozenWight",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.frozenWight)+_vm._s(row.unit))])]}}])}),_c('h3',{staticClass:"page-subtitle-shade"},[_vm._v(" 货位明细 ")]),_c('Table',{attrs:{"item-data":_vm.itemData,"list-data":_vm.listData,"loading":_vm.loading},scopedSlots:_vm._u([{key:"detailInvWeight",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.detailInvWeight)+_vm._s(row.unit))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }